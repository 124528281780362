import { TourRequestStatusEnum } from "../enums/TourRequestStatus.enum";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import OutboxIcon from "@mui/icons-material/Outbox";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import { TourRequestEnrollmentStatusEnum } from "../enums/ToureRequestEnrollmentStatus.enum";

export const HearAboutUsMap = new Map([
  [0, "Google"],
  [1, "Facebook"],
  [2, "Drive By"],
  [3, "Parent Referral"],
  [4, "Other"],
]);

export const TourRequestStatusMap = new Map([
  [
    TourRequestStatusEnum.Pending,
    {
      variant: "outlined",
      label: "Pending",
      color: "warning",
      icon: <TrackChangesIcon />,
    },
  ],
  [
    TourRequestStatusEnum.Responded,
    {
      variant: "outlined",
      label: "Future Availability",
      color: "warning",
      icon: <DoNotDisturbOnTotalSilenceIcon />,
    },
  ],
  [
    TourRequestStatusEnum.Scheduled,
    {
      variant: "",
      label: "Scheduled",
      color: "primary",
      icon: <ScheduleSendIcon />,
    },
  ],
  [
    TourRequestStatusEnum.Rescheduled,
    {
      variant: "outlined",
      label: "Rescheduled",
      color: "secondary",
      icon: <ScheduleSendIcon />,
    },
  ],
  [
    TourRequestStatusEnum.Confirmed,
    {
      variant: "",
      label: "Confirmed",
      color: "warning",
      icon: <CheckCircleOutlineIcon />,
    },
  ],
  [
    TourRequestStatusEnum.ScheduleRequested,
    {
      variant: "outlined",
      label: "Schedule Requested",
      color: "secondary",
      icon: <MoreTimeIcon />,
    },
  ],
  [
    TourRequestStatusEnum.Visited,
    {
      variant: "outlined",
      label: "Visited",
      color: "primary",
      icon: <CheckCircleIcon />,
    },
  ],
  [
    TourRequestStatusEnum.EnrolmentInitiated,
    {
      variant: "outlined",
      label: "Enrolment Initiated",
      color: "success",
      icon: <OutboxIcon />,
    },
  ],
  [
    TourRequestStatusEnum.Enrolled,
    {
      variant: "",
      label: "Enrolled",
      color: "success",
      icon: <VerifiedIcon />,
    },
  ],
  [
    TourRequestStatusEnum.Canceled,
    { variant: "", label: "Canceled", color: "error", icon: <CancelIcon /> },
  ],
  [
    TourRequestStatusEnum.Expired,
    {
      variant: "",
      label: "Expired",
      color: "error",
      icon: <HourglassBottomIcon />,
    },
  ],
]);

export const TourRequestEnrollmentStatusMap = new Map([
  [TourRequestEnrollmentStatusEnum.UNDECIDED, "Undecided"],
  [TourRequestEnrollmentStatusEnum.ENROLLING_NOW, "Enrolling by"],
  [TourRequestEnrollmentStatusEnum.ENROLLING_FUTURE, "Enrolling by"],
]);

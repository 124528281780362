import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';


export const getAllDocuments = async () => {
    try {
        const result = await trackPromise(axios.get('/documents'));
        const data = result.data;
        if (data.isSuccess) {
            return data.payload;
        }
    }
    catch (err) {
        return [];
    }
};
export const getDocumentById = async (id) => {
    try {
        const result = await trackPromise(axios.get(`/documents/${id}`));
        const data = result.data;
        if (data.isSuccess) {
            return data.payload;
        }
    }
    catch (err) {
        return (null);
    }
};
export const downloadDocumentByZohoId = async (id) => {
    try {
        const result = await trackPromise(axios.get(`/documents/${id}/download`));
        const data = result.data;
        if (data.isSuccess) {
            return data.payload;
        }
    }
    catch (err) {
        throw err;
    }
};
export const getSigningLink = async (id, requestId, actionId) => {
    try {
        let uri = `/documents/${id}/link`;
        if (!!requestId && !!actionId)
            uri += `?requestId=${requestId}&actionId=${actionId}`;
        const result = await trackPromise(axios.get(uri));
        const data = result.data;
        if (data.isSuccess) {
            window.open(data.payload, "_self", "noreferrer");
        }
    }
    catch (err) {
        throw err;
    }
};
export const updateDocumentStatus = async (id) => {
    try {
        const result = await trackPromise(axios.patch(`Documents/${id}/status`));
        const data = result.data;
        if (data.isSuccess) {
            return data.payload;
        }
    }
    catch (err) {
        throw err;
    }
};

const DocumentService = {
    getAllDocuments,
    getDocumentById,
    downloadDocumentByZohoId,
    getSigningLink,
    updateDocumentStatus
};

export default DocumentService; 
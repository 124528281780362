import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/data-table/DataTable";
import { TourRequestStatusMap } from "../../enumMaps/tourRequestEnum.map";
import "./tour-request-management.scss";
import TourRequestInfoModal from "./TourRequestInfoModal";
import {
  fetchAllTourRequests,
  getAllTourRequests,
  selectTourRequest,
  sendEnrolmentDocuments,
  updateTourRequest,
} from "./TourRequestSlice";
import useTitle from "../../hooks/useTitle";
import { useDispatch, useSelector } from "react-redux";
import { TourRequestStatusEnum } from "../../enums/TourRequestStatus.enum";
import { useParams, useSearchParams } from "react-router-dom";
import { getLoggedUserRoles } from "../auth/AuthSlice";

const columns = [
  {
    name: "parentName",
    disablePadding: false,
    label: "Parent Name",
    align: "left",
  },
  {
    name: "phoneNumber",
    disablePadding: false,
    label: "Phone Number",
    align: "left",
  },
  {
    name: "email",
    disablePadding: false,
    label: "E-mail",
    align: "left",
  },
  {
    name: "submittedDate",
    disablePadding: false,
    label: "Submitted On",
    align: "left",
    isDate: true,
  },
  // {
  //   name: "visitDate",
  //   disablePadding: false,
  //   label: "Visit Date",
  //   align: "left",
  //   isDate: true,
  // },
  {
    name: "status",
    disablePadding: false,
    label: "Status",
    align: "left",
  },
  {
    name: "action",
    disablePadding: false,
    label: "Action",
    align: "left",
  },
];

const TourRequestManagement = (prop) => {
  useTitle("Tour Requests");
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchAllTourRequests());
  }, [dispatch]);

  const [searchParams] = useSearchParams();

  let status; //= searchParams.get("status");

  switch (searchParams.get("status")) {
    case "new":
      status = TourRequestStatusEnum.Pending;
      break;
    case "responded":
      status = TourRequestStatusEnum.Responded;
      break;
    case "scheduled":
      status = TourRequestStatusEnum.Scheduled;
      break;
    case "rescheduled":
      status = TourRequestStatusEnum.Rescheduled;
      break;
    case "visited":
      status = TourRequestStatusEnum.Visited;
      break;
    case "enrolmentInitiated":
      status = TourRequestStatusEnum.EnrolmentInitiated;
      break;
    case "enrolled":
      status = TourRequestStatusEnum.Enrolled;
      break;
    case "canceled":
      status = TourRequestStatusEnum.Canceled;
      break;
    case "expired":
      status = TourRequestStatusEnum.Expired;
      break;
    default:
      status = null;
  }

  const tourRequests = useSelector(getAllTourRequests);
  const currentUserRoles = useSelector(getLoggedUserRoles);

  const [open, setOpen] = useState(false);
  const [selectedTourRequestId, setSelectedTourRequestId] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => {
    setOpen(false);
    setSelectedTourRequestId("");
  };

  const viewRequestInfo = (id) => {
    dispatch(selectTourRequest({ id }));
    handleModalOpen();
  };

  const sendJoinRequest = (id) => {
    dispatch(sendEnrolmentDocuments({ id: id }));
  };
  const markAsEnrolled = (id) => {
    dispatch(
      updateTourRequest({
        id: id,
        status: TourRequestStatusEnum.Enrolled,
      })
    );
  };

  useEffect(() => {
    if (!!id && tourRequests?.length > 0) {
      viewRequestInfo(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tourRequests]);

  const actionButtons = (id, status) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Button size="small" onClick={() => viewRequestInfo(id)}>
          View
        </Button>
        {(status === TourRequestStatusEnum.Scheduled ||
          status === TourRequestStatusEnum.Rescheduled ||
          status === TourRequestStatusEnum.Visited) && (
          <Button size="small" onClick={() => sendJoinRequest(id)}>
            Send Initial Documents
          </Button>
        )}
        {status === TourRequestStatusEnum.EnrolmentInitiated &&
          currentUserRoles.includes("SuperAdmin") && (
            <Button size="small" onClick={() => markAsEnrolled(id)}>
              Mark As Enrolled
            </Button>
          )}
      </ButtonGroup>
    );
  };

  const statusChip = (status) => {
    return (
      <Chip
        // size='small'
        label={TourRequestStatusMap.get(status).label}
        color={TourRequestStatusMap.get(status).color}
        variant={TourRequestStatusMap.get(status).variant}
        icon={TourRequestStatusMap.get(status).icon}
      />
    );
  };
  const compareByCreatedDate = (a, b) => {
    if (a.createdDate > b.createdDate) return -1;
    if (a.createdDate < b.createdDate) return 1;
    return 0;
  };
  const createRowData = (data) => {
    let rows = [];
    if (Array.isArray(data))
      if (status === null) {
        rows = [...data].sort(compareByCreatedDate).map((r) => ({
          id: r.id,
          parentName: `${r.parentFirstName}  ${r.parentLastName}`,
          email: r.parentEmail,
          phoneNumber: r.parentPhoneNumber,
          submittedDate: new Date(r.createdDate+'Z')?.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/Chicago",
          }),
          // visitDate: new Date(r.visitDate)?.toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' }),
          status: statusChip(r.status),
          action: actionButtons(r.id, r.status),
        }));
      } else
        rows = [...data]
          .sort(compareByCreatedDate)
          .filter((r) => r.status === status)
          .map((r) => ({
            id: r.id,
            parentName: `${r.parentFirstName}  ${r.parentLastName}`,
            email: r.parentEmail,
            phoneNumber: r.parentPhoneNumber,
            submittedDate: new Date(r.createdDate+'Z')?.toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            ),
            // visitDate: new Date(r.visitDate)?.toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' }),
            status: statusChip(r.status),
            action: actionButtons(r.id, r.status),
          }));

    return rows;
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box className="info-section">
          <DataTable
            isDense={false}
            rows={createRowData(tourRequests)}
            columns={columns}
            sortBy={"submittedDate"}
            order={"desc"}
          ></DataTable>
        </Box>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="tour-request-info"
      >
        <TourRequestInfoModal
          modalCloseHandle={handleModalClose}
          // tourRequest={Id}
          id={selectedTourRequestId}
        />
      </Dialog>
    </React.Fragment>
  );
};

TourRequestManagement.propTypes = {};

export default TourRequestManagement;

import React, { useEffect, useState } from "react";
import DataTable from "../../components/data-table/DataTable";
import { Box, Button, Dialog, useMediaQuery, useTheme } from "@mui/material";
import CreateParentModal from "./CreateParentModal";
import { Add } from "@mui/icons-material";
import ParentInfoModal from "./ParentInfoModal";
import useTitle from "../../hooks/useTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  closeParentModal,
  fetchAllParents,
  fetchParentById,
  getAllParents,
  getParentModalStatus,
  openParentModal,
  selectParent,
} from "./ParentsSlice";
import EditParentModel from "./EditParentModel";

const columns = [
  {
    name: "parentName",
    disablePadding: false,
    label: "Parent Name",
    align: "left",
  },
  {
    name: "email",
    disablePadding: false,
    label: "E-mail",
    align: "left",
  },
  {
    name: "phoneNumber",
    disablePadding: false,
    label: "Phone Number",
    align: "left",
  },
  {
    name: "children",
    disablePadding: false,
    label: "Children",
    align: "left",
  },
  {
    name: "action",
    disablePadding: false,
    label: "Action",
    align: "left",
  },
];

const ParentsManagement = (props) => {
  useTitle("Parents");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllParents());
  }, [dispatch]);
  let isModalOpen = useSelector(getParentModalStatus);

  const [open, setOpen] = useState(isModalOpen);
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [isCreateParent, setIsCreateParent] = useState(false);
  const [isViewParent, setIsViewParent] = useState(false);
  const [isEditParent, setIsEditParent] = useState(false);

  const openParentView = (id) => {
    setIsViewParent(true);
    setSelectedParentId(id);
    dispatch(selectParent({ id }));
    dispatch(fetchParentById({ id }));
    dispatch(openParentModal());
    setOpen(true);
  };
  const openParentEdit = (id) => {
    setIsEditParent(true);
    setSelectedParentId(id);
    dispatch(selectParent({ id }));
    dispatch(fetchParentById({ id }));
    dispatch(openParentModal());
    setOpen(true);
  };
  const openCreateParent = () => {
    setIsCreateParent(true);
    dispatch(openParentModal());
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
    dispatch(closeParentModal());

    setIsViewParent(false);
    setIsCreateParent(false);
    setIsEditParent(false);
    setSelectedParentId(null);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const actionButton = (id) => {
    return (
      <>
        <Button
          variant="text"
          id={"edit" + id}
          onClick={() => openParentEdit(id)}
        >
          Edit
        </Button>
        <Button variant="text" id={id} onClick={() => openParentView(id)}>
          View more
        </Button>
      </>
    );
  };

  const createRowData = (data) => {
    if (Array.isArray(data))
      return data.map((r) => ({
        id: r.id,
        parentName: r.fullName,
        email: r.email,
        phoneNumber: r.phoneNumber,
        children: r.children.toString(),
        action: actionButton(r.id),
      }));
    else return [];
  };

  const parents = useSelector(getAllParents);

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <div>
          <Button
            variant="outlined"
            onClick={openCreateParent}
            startIcon={<Add />}
          >
            Add Parent
          </Button>
        </div>

        <Box className="info-section">
          <DataTable
            isDense={true}
            rows={createRowData(parents)}
            columns={columns}
            sortBy={"parentName"}
          />
        </Box>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={!!open}
        onClose={handleModalClose}
        aria-labelledby="parent-info"
      >
        {isCreateParent && (
          <CreateParentModal modalCloseHandle={handleModalClose} />
        )}
        {isViewParent && (
          <ParentInfoModal
            modalCloseHandle={handleModalClose}
            id={selectedParentId}
          />
        )}
        {isEditParent && (
          <EditParentModel
            modalCloseHandle={handleModalClose}
            id={selectedParentId}
          />
        )}
      </Dialog>
    </React.Fragment>
  );
};

// ParentsManagement.propTypes = {};

export default ParentsManagement;

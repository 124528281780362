import React from "react";
import PropTypes from "prop-types";
import ModalContent from "../../components/Modal/ModalContent";
import { Button } from "@mui/material";
import EmployeeForm from "./EmployeeForm";
import { createEmployee } from "./EmployeeSlice";
import { useDispatch } from "react-redux";

const CreateEmployeeModal = (props) => {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();

  const onClickCreateEmployee = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const handleSubmit = async (d) => {
    let employeeData = {
      ...d,
    };
    dispatch(createEmployee(employeeData));
  };

  const content = (
    <EmployeeForm
      onSubmit={handleSubmit}
      formRef={formRef}
    />
  );

  const actions = (
    <React.Fragment>
      <Button
        onClick={onClickCreateEmployee}
        variant='contained'
        color='success'>
        Create Employee
      </Button>
    </React.Fragment>
  );

  return (
    <ModalContent
      title={"Add Employee"}
      content={content}
      action={actions}
      handleClose={props.modalCloseHandle}
    />
  );
};

CreateEmployeeModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => (
  <CreateEmployeeModal
    {...props}
    forwardedRef={ref}
  />
));

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { TourRequestEnrollmentStatusEnum } from "../../enums/ToureRequestEnrollmentStatus.enum";
import { useDispatch } from "react-redux";
import { updateTourRequest } from "./TourRequestSlice";
import { TourRequestStatusEnum } from "../../enums/TourRequestStatus.enum";

const ParentsResponse = (props) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(
    props.request.EnrollmentStatus ||
      TourRequestEnrollmentStatusEnum.ENROLLING_NOW
  );
  const [error, setError] = useState(null);
  const [enrollmentDate, setEnrollmentDate] = useState(
    props.request.EnrollmentDate || ""
  );

  const submit = () => {
    if (
      status !== TourRequestEnrollmentStatusEnum.UNDECIDED &&
      !enrollmentDate
    ) {
      setError("Please pick the enrollment date.");
      return;
    }
    setError(null);
    dispatch(
      updateTourRequest({
        id: props?.request?.id,
        enrollmentStatus: status,
        enrollmentDate:
          enrollmentDate +
          (status === TourRequestEnrollmentStatusEnum.ENROLLING_FUTURE
            ? "-01"
            : ""),
        status: TourRequestStatusEnum.Visited,
      })
    );
    props.onClose();
  };
  const handleChange = (event) => {
    setStatus(Number(event.target.value));
    setEnrollmentDate("");
    setError(null);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      sx={{ minHeight: "50vh" }}
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="tour-request-info"
    >
      <DialogContent>
        <Box display={"flex"} gap={"2rem"} flexWrap={"wrap"}>
          <Box display={"flex"} flexDirection={"column"} minWidth={"15rem"}>
            <FormLabel>Parent's response.</FormLabel>
            <RadioGroup
              value={status}
              onChange={handleChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={TourRequestEnrollmentStatusEnum.ENROLLING_NOW}
                control={<Radio />}
                label="Enrolling Now"
              />
              <FormControlLabel
                value={TourRequestEnrollmentStatusEnum.ENROLLING_FUTURE}
                control={<Radio />}
                label="Enrolling in Future"
              />
              <FormControlLabel
                value={TourRequestEnrollmentStatusEnum.UNDECIDED}
                control={<Radio />}
                label="Undecided"
              />
            </RadioGroup>
          </Box>
          {status !== TourRequestEnrollmentStatusEnum.UNDECIDED && (
            <Box display={"flex"} flexDirection={"column"}>
              <FormLabel>Enrollment Date</FormLabel>
              <TextField
                error={error != null}
                size="small"
                autoFocus
                margin="none"
                id="enrollment-date"
                placeholder="Enrollment Date"
                value={enrollmentDate}
                type={
                  status === TourRequestEnrollmentStatusEnum.ENROLLING_NOW
                    ? "date"
                    : "month"
                }
                inputProps={{ min: props.MIN_MONTH }}
                onInput={(e) => {
                  setEnrollmentDate(e.target.value);
                  setError(null);
                }}
                helperText={error}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={submit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParentsResponse;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import { requestTimeSlot } from "../../services/tour-request-management.service";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../layout/layoutSlice";
import { Box, Typography } from "@mui/material";

const cardStyle = {
  padding: "2rem",
  borderRadius: "3rem",
  background: "#ffffff",
  boxShadow: "20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff",
};
const backgroundStyle = {
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 2rem",
  background: "#ffffff",
};

const RequestTourDates = () => {
  useTitle("Request Tour Dates");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    requestTimeSlot(id)
      .then((data) => {
        if (!!data) {
          setIsSuccess(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(
          openSnackbar({
            isOpen: true,
            message: err,
            severity: "error",
          })
        );
        setIsSuccess(false);
        setLoading(false);
      });
  }, [dispatch, id]);
  if (loading) return null;
  else if (isSuccess)
    return (
      <Box sx={backgroundStyle}>
        <Box sx={cardStyle}>
          <Typography variant="h4">Request Success</Typography>
          <br />
          <Box
            sx={{
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              maxWidth: "50rem",
            }}
          >
            <Typography variant="body1">
              Your request has been recorded and we'll get back to you with
              available time slots for a tour.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  else
    return (
      <Box sx={backgroundStyle}>
        <Box sx={cardStyle}>
          <Typography variant="h4">Request Failed</Typography>
          <br />
          <Box
            sx={{
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              maxWidth: "50rem",
            }}
          >
            <Typography variant="body1">
              An error occurred while requesting. Please send a request to
              Center supervisor (
              <a href="mailto:info@radiant-montessori.com">
                info@radiant-montessori.com
              </a>
              ) via email.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
};

export default RequestTourDates;

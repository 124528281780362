import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { EnhancedTableHead } from "./EnhancedTableHead";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, alpha, styled } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "10px",
  backgroundColor: alpha("#FFFFFF", 0.65),
  "&:hover": {
    backgroundColor: alpha("#FFFFFF", 0.85),
  },
  marginRight: theme.spacing(2),
  // marginBottom: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "fit-content",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    borderRadius: "10px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50ch",
    },
  },
  '& .MuiInputBase-input:focus': {
    boxShadow: "8px 8px 20px 1px #00000020",
  }
}));

const getSortedArray = (array, order, orderBy, isDateSort) => {
  const compareFunction = (a, b) => {
    const valueA = isDateSort ? new Date(a[orderBy]) : a[orderBy];
    const valueB = isDateSort ? new Date(b[orderBy]) : b[orderBy];

    if (order === "asc") {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else if (order === "desc") {
      if (valueA > valueB) return -1;
      if (valueA < valueB) return 1;
    }

    return 0;
  };
  return array.slice().sort(compareFunction);
};

const DataTable = (props) => {
  const { rows, columns, isDense, sortBy, isSelectable, hideSearch, filters } =
    props;

  const [order, setOrder] = useState(!props.order ? "asc" : props.order);
  const [orderBy, setOrderBy] = useState(sortBy);
  const [isDateSort, setIsDateSort] = useState(
    sortBy.toLowerCase().includes("date")
  );
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    setFilteredRows(
      rows.filter((obj) =>
        Object.values(obj).some((val) =>
          String(val).toLowerCase().includes(searchString)
        )
      )
    );
  }, [rows, searchString]);

  const onSearch = (e) => {
    setSearchString(e.target.value.toLowerCase());
  };

  const handleRequestSort = (event, property, isDateCol) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setIsDateSort(isDateCol);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginBottom: "0.5rem",
            flexWrap: "wrap",
            gap: "0.25rem",
          }}
        >
          {!hideSearch && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onInput={onSearch}
              />
            </Search>
          )}
          {filters}
        </Box>
      </Box>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ maxHeight: "calc(100vh -  12rem)" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={isDense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows?.length | 0}
              isSelectable={isSelectable}
              columns={columns}
            />
            <TableBody>
              {
                // filteredRows
                //   .sort(getComparator(order, orderBy, isDateSort))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                getSortedArray(filteredRows, order, orderBy, isDateSort).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {isSelectable && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                        )}
                        {columns.map((c, colIndex) => (
                          <TableCell align={c.align || "left"} key={colIndex}>
                            {row[c.name]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  }
                )
              }
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (isDense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={columns.length + isSelectable ? 1 : 0} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            color: "#707070",
            padding: "0.5rem",
            borderTop: "1px solid #e7e7e7",
            fontWeight: 500,
            fontSize: "0.9rem",
          }}
        >
          {rows.length + " "}Rows{" "}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[1, 5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
};

DataTable.defaultProps = {
  hideSearch: false,
  isDense: true,
};
DataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isDense: PropTypes.bool,
  sortBy: PropTypes.string,
  order: PropTypes.string,
  isSelectable: PropTypes.bool,
  hideSearch: PropTypes.bool,
};

export default DataTable;

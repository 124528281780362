import React, { useEffect, useState } from "react";
import DataTable from "../../components/data-table/DataTable";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ClassroomMap } from "../../enumMaps/clssroomEnum.map";
import useTitle from "../../hooks/useTitle";
import { useSelector, useDispatch } from "react-redux";
import {
  clearSelectedChild,
  deleteChildById,
  // fetchChild,
  selectChild,
  fetchChildren,
  getAllChildren,
} from "./childrenSlice";
import EditChildModal from "./EditChildModal";
import { Add } from "@mui/icons-material";
import AddChildModal from "./AddChildModal";
import ModalContent from "../../components/Modal/ModalContent";
import { StudentStatus } from "../../enums/StudentStatus.enum";

const columns = [
  {
    name: "childName",
    disablePadding: false,
    label: "Child Name",
    align: "left",
  },
  {
    name: "classroom",
    disablePadding: false,
    label: "Classroom",
    align: "left",
  },
  {
    name: "dob",
    disablePadding: false,
    label: "Date Of Birth",
    align: "left",
    isDate: true,
  },
  {
    name: "age",
    disablePadding: false,
    label: "Age",
    align: "left",
  },
  {
    name: "parent",
    disablePadding: false,
    label: "Parent",
    align: "left",
  },
  {
    name: "action",
    disablePadding: false,
    label: "Action",
    align: "right",
  },
];

const ChildrenManagement = (props) => {
  useTitle("Children Management");
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isAddChildOpen, setIsAddChildOpen] = useState(false);
  const [isRemoveChildOpen, setIsDeleteChildOpen] = useState(false);
  const [reasonToRemove, setReasonToRemove] = useState("");
  const [removingNote, setRemovingNote] = useState("");
  const [selectedChild, setSelectedChild] = useState(null);
  let children = useSelector(getAllChildren);
  const [id, setId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(StudentStatus.ENROLLED);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const openChildEdit = (id) => {
    dispatch(selectChild({ id }));
    setOpen(true);
  };

  const openAddChild = () => {
    setIsAddChildOpen(true);
  };

  const closeAddChild = () => {
    setIsAddChildOpen(false);
  };

  const onClickRemove = (reason) => {
    setReasonToRemove(reason);
    setAnchorEl(null);
    setSelectedChild(children.filter((child) => child.id === id)[0]);
    setIsDeleteChildOpen(true);
  };

  const removeChild = () => {
    if (selectedChild) {
      dispatch(
        deleteChildById({
          id: selectedChild.id,
          reason: reasonToRemove,
          note: removingNote,
        })
      );
      setIsDeleteChildOpen(false);
      setSelectedChild(null);
    }
  };

  const handleModalClose = () => {
    dispatch(clearSelectedChild());
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const actionBtn = (id) => {
    return (
      <>
        <Button
          variant="text"
          id={"edit" + id}
          onClick={() => openChildEdit(id)}
        >
          Edit
        </Button>

        <Button
          color="error"
          variant="text"
          id={"remove-" + id}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setId(id);
          }}
        >
          Remove
        </Button>
      </>
    );
  };

  const createRowData = (data) => {
    if (Array.isArray(data))
      return data.map((r) => ({
        id: r.id,
        childName: `${r.firstName} ${r.lastName}`,
        classroom: ClassroomMap.get(r.classroom),
        dob: new Date(r.dob + "Z").toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          timeZone: "America/Chicago",
        }),
        age:
          r.age * 7 > 365
            ? (r.age / 52).toFixed(1) + " Years"
            : r.age > 36
            ? (r.age / 4).toFixed(1) + " Months"
            : r.age + " Weeks",
        parent: r.parentFullName,
        action: r.status === StudentStatus.ENROLLED ? actionBtn(r.id) : null,
      }));
    else return [];
  };

  useEffect(() => {
    dispatch(fetchChildren());
  }, [dispatch]);

  const filterButtons = [
    <Button
      onClick={() => setSelectedStatus(StudentStatus.ENROLLED)}
      variant={
        selectedStatus === StudentStatus.ENROLLED ? "contained" : "outlined"
      }
      key={StudentStatus.ENROLLED}
    >
      Enrolled
    </Button>,
    <Button
      onClick={() => setSelectedStatus(StudentStatus.GRADUATED)}
      variant={
        selectedStatus === StudentStatus.GRADUATED ? "contained" : "outlined"
      }
      key={StudentStatus.GRADUATED}
    >
      Graduated
    </Button>,
    <Button
      onClick={() => setSelectedStatus(StudentStatus.LEFT)}
      variant={selectedStatus === StudentStatus.LEFT ? "contained" : "outlined"}
      key={StudentStatus.LEFT}
    >
      Left
    </Button>,
  ];

  const filters = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <ButtonGroup size="small">{filterButtons}</ButtonGroup>
    </Box>
  );

  return (
    <React.Fragment>
      {/* Main Section */}
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <div>
          <Button variant="outlined" onClick={openAddChild} startIcon={<Add />}>
            Add a Child
          </Button>
        </div>
        <Box
          className="info-section"
          sx={{
            width: "inherit",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 1.5,
          }}
        >
          <DataTable
            filters={filters}
            isDense={true}
            rows={createRowData(
              children.filter((c) => c.status === selectedStatus)
            )}
            columns={columns}
            sortBy={"childName"}
          />
        </Box>
      </Box>

      {/* Edit Child Modal */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="child-info"
      >
        <EditChildModal modalCloseHandle={handleModalClose} />
      </Dialog>
      {/* Add Child Modal */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={isAddChildOpen}
        onClose={closeAddChild}
        aria-labelledby="child-info"
      >
        <AddChildModal modalCloseHandle={closeAddChild} />
      </Dialog>
      {/* Remove Child Modal */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={isRemoveChildOpen}
        onClose={handleModalClose}
        aria-labelledby="child-info"
      >
        {selectedChild && (
          <ModalContent
            title={"Remove Child"}
            content={
              <Box p={5} sx={{ display: "flex", flexDirection: "column" }}>
                <span>
                  Do you want to{" "}
                  {reasonToRemove === StudentStatus.DELETED && (
                    <i style={{ color: "red", fontWeight: "bold" }}>
                      permanently
                    </i>
                  )}{" "}
                  remove{" "}
                  <strong>
                    {selectedChild?.firstName + " " + selectedChild?.lastName}
                  </strong>{" "}
                  from the system
                  {reasonToRemove === StudentStatus.LEFT
                    ? " as left"
                    : reasonToRemove === StudentStatus.GRADUATED
                    ? " as graduated"
                    : ""}
                  ?
                </span>

                {(reasonToRemove === StudentStatus.LEFT ||
                  reasonToRemove === StudentStatus.DELETED) && (
                  <textarea
                    style={{
                      border: "1px solid #bc89ff",
                      borderRadius: "0.5rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                    value={removingNote}
                    onChange={(e) => setRemovingNote(e.target.value)}
                    aria-label="minimum height"
                    rows={5}
                    placeholder={`Reason to ${
                      reasonToRemove === StudentStatus.LEFT
                        ? "leaving"
                        : "remove"
                    } (optional)`}
                  />
                )}
              </Box>
            }
            action={
              <Button variant="contained" color="error" onClick={removeChild}>
                Remove
              </Button>
            }
            handleClose={() => {
              setIsDeleteChildOpen(false);
              setRemovingNote("");
              setSelectedChild(null);
            }}
          />
        )}
      </Dialog>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onClickRemove(StudentStatus.GRADUATED);
          }}
        >
          Graduated
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickRemove(StudentStatus.LEFT);
          }}
        >
          Left
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickRemove(StudentStatus.DELETED);
          }}
        >
          Permanent Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

// ChildrenManagement.propTypes = {};

export default ChildrenManagement;

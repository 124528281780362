import React from "react";
import { Outlet } from "react-router-dom";
import "./layout.scss";
import TopBar from "./topBar/TopBar";
import Sidebar from "./sidebar/Sidebar";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, getSnackbarData } from "./layoutSlice";

const Toast = ({ open, message, severity, onClose }) => {
  if (!open) return null;
  return (
    <Snackbar
      open={!!message && open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}>
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const Layout = ({ isPublic }) => {
  const dispatch = useDispatch();
  let snackBarData = useSelector(getSnackbarData);

  const handleCloseSnackbar = () => {
    dispatch(closeSnackbar());
  };
  return (
    <div className='layout'>
      <Toast
        open={snackBarData.isOpen}
        message={snackBarData.message}
        severity={snackBarData.severity}
        onClose={handleCloseSnackbar}
      />
      {(!isPublic && (
        <>
          <Sidebar />
          <div className='main-content'>
            <Outlet />
          </div>
        </>
      )) || <Outlet />}
    </div>
  );
};

export default Layout;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EmployeesService from '../../services/employees.service';

const initialState = {
    employeesList: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    selectedEmployee: null,
    isModalOpen: false
};
export const createEmployee = createAsyncThunk('employees/create', async (employeeData) => {
    const response = await EmployeesService.createEmployee(employeeData);
    return response;
});
export const updateEmployee = createAsyncThunk('employees/update', async (id, employeeData) => {
    const response = await EmployeesService.updateEmployeeById(id, employeeData);
    return response;
});
export const fetchEmployees = createAsyncThunk('employees/fetchAll', async () => {
    const response = await EmployeesService.getAllEmployees();
    return response;
});
export const fetchEmployeeById = createAsyncThunk('employees/fetchById', async (id) => {
    const response = await EmployeesService.getEmployeeById(id);
    return response;
});
export const deleteEmployeeById = createAsyncThunk('employees/delete', async (id) => {
    const response = await EmployeesService.deleteEmployeeById(id);
    return response;
});

export const employeesSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        clearSelectedEmployee: (state) => {
            state.selectedEmployee = null;
        },
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
        selectEmployee: (state, { payload }) => {
            state.selectedEmployee = state.employeesList.find(emp => emp.id === payload.id);
        },
    },
    extraReducers(builder) {
        builder
            // Create
            .addCase(createEmployee.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEmployee.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.employeesList = [action.payload, ...state.employeesList];
                state.selectedEmployee = null;
                state.isModalOpen = false;
            })
            .addCase(createEmployee.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedEmployee = null;
                state.error = action.error.message;
            })
            // Update
            .addCase(updateEmployee.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEmployee.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.employeesList = [...state.employeesList.map(e => e.id === action.payload.id ? action.payload : e)];
                state.selectedEmployee = null;
                state.isModalOpen = false;
            })
            .addCase(updateEmployee.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedEmployee = null;
                state.error = action.error.message;
            })
            // Fetch all
            .addCase(fetchEmployees.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEmployees.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.employeesList = [...action?.payload];
            })
            .addCase(fetchEmployees.rejected, (state, action) => {
                state.status = 'failed';
                state.employeesList = [];
                state.error = action.error.message;
            })
            // Fetch By ID
            .addCase(fetchEmployeeById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEmployeeById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedEmployee = action?.payload;
            })
            .addCase(fetchEmployeeById.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedEmployee = null;
                state.error = action.error.message;
            })
            // Delete
            .addCase(deleteEmployeeById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteEmployeeById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.employeesList = state.employeesList.filter(c => c.id !== action.meta.arg);
                state.selectedEmployee = null;
            })
            .addCase(deleteEmployeeById.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedEmployee = null;
                state.error = action.error.message;
            });
    }
});

// // Action creators are generated for each case reducer function
export const {
    selectEmployee,
    clearSelectedEmployee,
    openModal,
    closeModal,
} = employeesSlice.actions;

//Selectors
export const getAllEmployees = (state) => state.employees?.employeesList;
export const getSelectedEmployee = (state) => state.employees.selectedEmployee;
export const getEmployeeModalStatus = (state) => state.employees.isModalOpen;

export default employeesSlice.reducer;
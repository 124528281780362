import { Backdrop, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { usePromiseTracker } from 'react-promise-tracker';
import { Provider, useDispatch, useSelector } from 'react-redux';
import App from './App';
import store from './app/store';
import { authService } from './services/auth.service';
import axios, { setAuthToken } from './config/API';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { getAuthToken, getRefreshToken, logout, updateTokens } from './features/auth/AuthSlice';
import './main.css';
import { openSnackbar } from './layout/layoutSlice';

const AuthWrapper = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(getAuthToken);
  const refreshToken = useSelector(getRefreshToken);
  setAuthToken(authToken);

  axios.interceptors.response.use(response => {
    if (response?.data?.isSuccess === false) {
      dispatch(openSnackbar({ isOpen: true, message: response.data.message || "Action Failed!", severity: 'error' }));
      return Promise.reject(response.response.data);
    } else {
      dispatch(openSnackbar({ isOpen: true, message: response.data.message || "", severity: 'success' }));
      return response;
    }
  }, async error => {
    const originalRequest = error.config;
    if (error.response.status === 403) {
      dispatch(openSnackbar({ isOpen: true, message: "Action Unauthorized!", severity: 'error' }));
      dispatch(logout());
    }
    if (401 === error.response.status) {
      dispatch(openSnackbar({ isOpen: true, message: error.response.data || "Action Unauthorized or session expired, please login again!", severity: 'error' }));
      dispatch(logout());
      // if (!refreshToken || !authToken) {
      //   dispatch(logout());
      // }

      // try {
      //   // TODO once BE token refresh fixed
      //   const result = await authService.getTokenRefresh(authToken, refreshToken);

      //   if (!result.data.isSuccess) {
      //     this.setState({ error: result.data.message, isError: true });
      //     dispatch(logout());
      //   }
      //   else {
      //     dispatch(updateTokens({ refreshToken: result.data.dto.refreshToken, authToken: result.data.dto.authToken }));
      //     setAuthToken(authToken);

      //     return axios(originalRequest);
      //   }
      // }
      // catch (err) {
      //   dispatch(logout());
      // }
    }
    else if (error.response.status === 404) {
      dispatch(openSnackbar({ isOpen: true, message: error.response.data || "Action Failed!", severity: 'error' }));
      window.location.replace("/notfound");
    }
    else {
      dispatch(openSnackbar({ isOpen: true, message: error.response.data || "Action Failed!", severity: 'error' }));
      return Promise.reject(error.response.data);
    }
  });

  return (
    <App />
  );
};

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column', gap: 2 }}
        open={true}>
        <CircularProgress color="secondary" size={75} />
        <Typography variant='h5'>
          Loading...
        </Typography>
      </Backdrop>
    )
  );
};


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // staleTime: 5*60*1000,
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LoadingIndicator />
        <BrowserRouter>
          <AuthWrapper />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

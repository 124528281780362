import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TemplateService from '../../services/templates-management-service';

const initialState = {
    templateList: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    selectedTemplate: null,
    isModalOpen: false
};

export const createTemplate = createAsyncThunk('templates/create', async (data) => {
    const response = await TemplateService.createTemplate(data);
    return response;
});
export const updateTemplate = createAsyncThunk('templates/update', async (data) => {
    const response = await TemplateService.updateTemplateById(data);
    return response;
});
export const fetchAllTemplates = createAsyncThunk('templates/fetchAll', async () => {
    const response = await TemplateService.getAllTemplates();
    return response;
});
export const fetchTemplateById = createAsyncThunk('templates/fetchById', async (id) => {
    const response = await TemplateService.getTemplateById(id);
    return response;
});
export const deleteTemplateById = createAsyncThunk('templates/delete', async (id) => {
    const response = await TemplateService.deleteTemplateById(id);
    return response;
});
export const sendToSign = createAsyncThunk('templates/send', async (data) => {
    const response = await TemplateService.sendToSign(data.templateId, data.requestData);
    return response;
});
export const bulkSend = createAsyncThunk('templates/bulkSend', async (data) => {
    const response = await TemplateService.bulkSend(data.templateId, data.category);
    return response;
});

export const templatesSlice = createSlice({
    name: 'templates',
    initialState: initialState,
    reducers: {
        clearSelectedTemplate: (state) => {
            state.selectedTemplate = null;
        },
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
        selectTemplate: (state, { payload }) => {
            state.selectedTemplate = state.templateList.find(temp => temp.id === payload.id);
        },
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(createTemplate.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createTemplate.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.templateList = [action.payload, ...state.templateList];
                state.selectedTemplate = null;
                state.isModalOpen = false;
            })
            .addCase(createTemplate.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedTemplate = null;
                state.error = action.error.message;
            })
            // Update
            .addCase(updateTemplate.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateTemplate.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.templateList = [...state.templateList.map(e => e.id === action.payload.id ? action.payload : e)];
                state.selectedTemplate = null;
                state.isModalOpen = false;
            })
            .addCase(updateTemplate.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedTemplate = null;
                state.error = action.error.message;
            })
            // Fetch all
            .addCase(fetchAllTemplates.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllTemplates.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.templateList = [...action?.payload];
            })
            .addCase(fetchAllTemplates.rejected, (state, action) => {
                state.status = 'failed';
                state.templateList = [];
                state.error = action.error.message;
            })
            // Fetch By ID
            .addCase(fetchTemplateById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTemplateById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedTemplate = action?.payload;
            })
            .addCase(fetchTemplateById.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedTemplate = null;
                state.error = action.error.message;
            })
            // Delete
            .addCase(deleteTemplateById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteTemplateById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.templateList = state.templateList.filter(c => c.id !== action.meta.arg);
                state.selectedTemplate = null;
            })
            .addCase(deleteTemplateById.rejected, (state, action) => {
                state.status = 'failed';
                state.selectedEmployee = null;
                state.error = action.error.message;
            })            
            // Send single
            .addCase(sendToSign.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendToSign.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedTemplate = null;
                state.isModalOpen = false;
            })
            .addCase(sendToSign.rejected, (state, action) => {
                state.status = 'failed';
            })
            // Send Bulk
            .addCase(bulkSend.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(bulkSend.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedTemplate = null;
                state.isModalOpen = false;
            })
            .addCase(bulkSend.rejected, (state, action) => {
                state.status = 'failed';
            });
            
    }
});

// Action creators are generated for each case reducer function
export const {
    clearSelectedTemplate,
    openModal,
    closeModal,
    selectTemplate
 } = templatesSlice.actions;

//Selectors
export const getAllTemplates = (state) => state.templates.templateList;
export const getSelectedTemplate = (state) => state.templates.selectedTemplate;
export const getTemplateModalStatus = (state) => state.templates.isModalOpen;
export const getTemplateError = (state) => state.templates.error;
export const getApiStatus = (state) => state.templates.status;

export default templatesSlice.reducer;
import axios, { downloadRequest } from "../config/API";
import { trackPromise } from "react-promise-tracker";

export const getAllResources = async () => {
  try {
    const result = await trackPromise(axios.get("/Resources"));
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    return [];
  }
};

export const getMyFolders = async () => {
  try {
    const result = await trackPromise(axios.get("/Resources/myfolders"));
    const data = result.data;
    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    return [];
  }
};

export const uploadFile = async (files, folderPath) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append("files", file, folderPath + "/" + file.name);
  });

  const url = `/Resources/upload?folder=${encodeURIComponent(folderPath)}`;
  try {
    const result = await trackPromise(
      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );

    const data = result.data;

    if (data.isSuccess) {
      return data.payload;
    }
  } catch (err) {
    console.error("Error uploading file:", err);
    return [];
  }
};

export const downloadResource = async (id, filename) => {
  downloadRequest({
    url: `/Resources/${id}/download`,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => response?.data)
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

const ResourcesService = {
  getAllResources,
  downloadResource,
  getMyFolders,
  uploadFile,
};

export default ResourcesService;

import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editChild, getSelectedChild } from "./childrenSlice";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import ModalContent from "../../components/Modal/ModalContent";
import PropTypes from "prop-types";

const EditChildModal = ({ modalCloseHandle, id }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const child = useSelector(getSelectedChild);
  const [classroom, setClassroom] = useState(child?.classroom || 0);

  const formRef = React.useRef(null);

  const onClickUpdate = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmit = async (d) => {
    let childData = {
      ...child,
      firstName: d.firstName,
      lastName: d.lastName,
      dob: d.dob,
      classroom: classroom,
    };
    console.log("🚀 ~ onSubmit ~ childData:", childData);
    dispatch(editChild(childData));
    modalCloseHandle();
  };
  const handleChangeClassroom = (event) => {
    setClassroom(event.target.value);
  };

  const content = (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
      ref={formRef}
    >
      <Grid p={2} container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            defaultValue={child?.firstName}
            {...register(`firstName`, {
              required: "Child's first name is required.",
            })}
            label={"Child's First Name*"}
            error={errors?.firstName !== undefined ? errors?.firstName : false}
            helperText={
              errors?.firstName !== undefined ? errors?.firstName?.message : ""
            }
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            defaultValue={child?.lastName}
            {...register(`lastName`, {
              required: "Child's last name is required.",
            })}
            label={"Last Name*"}
            error={errors?.lastName !== undefined ? errors?.lastName : false}
            helperText={
              errors?.lastName !== undefined ? errors?.lastName?.message : ""
            }
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            defaultValue={child?.dob?.split("T")[0]}
            InputLabelProps={{ shrink: true }}
            type={"date"}
            inputProps={{
              inputMode: "date",
              max: new Date().toISOString().split("T")[0],
            }}
            {...register(`dob`, {
              required: "Child's date of birth is required.",
              valueAsDate: true,
              max: {
                value: new Date(),
                message: "Child's date of birth must be before today.",
              },
            })}
            label={"Date of Birth*"}
            error={errors?.dob !== undefined ? errors.dob : false}
            helperText={errors?.dob !== undefined ? errors?.dob?.message : ""}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            value={classroom} // Use controlled value from state
            onChange={handleChangeClassroom} // Handle change to update state
            label="Classroom"
            error={errors?.classroom !== undefined ? errors?.classroom : false}
            helperText={
              errors?.classroom !== undefined ? errors?.classroom?.message : ""
            }
          >
            <MenuItem value={0}>Preschool</MenuItem>
            <MenuItem value={1}>Toddler</MenuItem>
            <MenuItem value={2}>Transition Room</MenuItem>
            <MenuItem value={3}>Infants</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );

  const actions = (
    <Fragment>
      <Button onClick={onClickUpdate} variant="contained" color="success">
        Update Child
      </Button>
    </Fragment>
  );

  return (
    <ModalContent
      title={"Edit " + child?.lastName + ", " + child?.firstName}
      content={content}
      action={actions}
      handleClose={modalCloseHandle}
    />
  );
};

EditChildModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default EditChildModal;

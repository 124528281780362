import React, { useEffect, useState } from "react";
import ResourcesService from "../../services/resources.service";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import DataTable from "../../components/data-table/DataTable";
import { Delete, UploadFile } from "@mui/icons-material";

import SearchIcon from "@mui/icons-material/Search";
import { Search, SearchIconWrapper, StyledInputBase } from "./MyFolders.style";

const columns = [
  {
    name: "name",
    disablePadding: false,
    label: "Name",
    align: "left",
  },
  {
    name: "action",
    disablePadding: false,
    label: "Action",
    align: "right",
  },
];

const MyFolders = () => {
  const [folders, setFolders] = useState(new Map());
  const [folderNames, setFolderNames] = useState([]);
  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    ResourcesService.getMyFolders()
      .then((data) => {
        if (!!data) {
          setFolderNames(Object.keys(data));
          setFolders(new Map(Object.entries(data)));
          const [firstFolderName] = Object.keys(data);
          setSelectedFolderName(firstFolderName);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(false);
    }
    console.log(selectedFiles);
  }, [selectedFiles]);

  const actionBtn = (id, name) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Button
          variant="text"
          id={"view" + id}
          onClick={() => ResourcesService.downloadResource(id, name)}
        >
          Download
        </Button>
      </ButtonGroup>
    );
  };

  const createRowData = (folders) => {
    if (!folders) return [];

    return folders?.map((r) => {
      return { id: r.id, name: r.name, action: actionBtn(r.id, r.name) };
    });
  };

  const onchangeFolder = (e, newValue) => {
    setSelectedFiles([]);
    setSelectedFolderName(newValue);
  };

  const openFileSelector = () => {
    document.getElementById("fileInput").value = null;
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    setSelectedFiles(filesArray);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleDialogClose = () => {
    setSelectedFiles([]);
    setIsDialogOpen(false);
  };
  const cancelUpload = () => {
    setSelectedFiles([]);
    handleDialogClose();
  };
  const handleUploadConfirm = () => {
    ResourcesService.uploadFile(selectedFiles, selectedFolderName).catch(err=>{
      console.error(err);
      setSelectedFiles([]);
    }).then((res)=>{      
      folders?.get(selectedFolderName).push(...res);
      setFolders(new Map(folders));
      handleDialogClose();
    });
  };
  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100% " }}>
        <Box
          sx={{
            justifyContent: "space-around",
            display: "flex",
            zIndex: "100",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={selectedFolderName}
            onChange={onchangeFolder}
          >
            {/* <Tab label="All" value={"All"} /> */}
            {folderNames?.map((name) => (
              <Tab key={name} label={name?.split("/")?.pop()} value={name} />
            ))}
          </Tabs>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "calc(100% - 2rem)",
            position: "relative",
          }}
          key={"my-folders-container"}
          className="info-section"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap-reverse",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Search theme={theme}>
              <SearchIconWrapper theme={theme}>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                theme={theme}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onInput={onSearch}
              />
            </Search>

            {!!selectedFolderName && (
              <>
                <input
                  id="fileInput"
                  type="file"
                  onInput={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={openFileSelector}
                  sx={{ height: "fit-content" }}
                  startIcon={<UploadFile />}
                >
                  Upload to "{selectedFolderName.split("/")?.pop()}"
                </Button>
              </>
            )}
          </Box>

          <DataTable
            rows={createRowData(
              folders
                ?.get(selectedFolderName)
                ?.filter((r) => r.name.toLowerCase().includes(searchText))
            )}
            columns={columns}
            sortBy={"name"}
            hideSearch={true}
            isDense={true}
          />
        </Box>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="upload-confirm-dialog"
      >
        <DialogTitle>Confirm File Upload</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Are you sure you want to upload the following files?
          </Typography>
          <List>
            {selectedFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  sx={{ ":hover": { color: "#FF0000" } }}
                  onClick={() => handleRemoveFile(index)}
                >
                  <Delete />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUploadConfirm} color="primary">
            Confirm Upload
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MyFolders;

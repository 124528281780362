import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import ModalContent from "../../components/Modal/ModalContent";
import { useSelector } from "react-redux";
import { getSelectedParent } from "./ParentsSlice";

const cardContent = (data) => {
  if (data)
    return (
      <Box sx={{ padding: "2rem 0" }}>
        <Divider textAlign='left'>
          <Chip label='Parent Details' />
        </Divider>
        <Grid
          container
          p={3}
          rowSpacing={1}
          columnSpacing={{ sm: 1, md: 2 }}>
          <Grid
            item
            xs={12}
            md={6}>
            <Typography
              variant='caption'
              fontSize={17}>
              Parent Name :{" "}
            </Typography>
            {data?.fullName}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <Typography
              variant='caption'
              fontSize={17}>
              Parent Email :{" "}
            </Typography>
            {data?.email}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <Typography
              variant='caption'
              fontSize={17}>
              Parent Phone Number :{" "}
            </Typography>
            {data?.phoneNumber}
          </Grid>
        </Grid>
        <Divider textAlign='left'>
          <Chip label='Children Details' />
        </Divider>
        {data?.children.map((child, index) => {
          return (
            <React.Fragment key={index}>
              <Grid
                container
                p={3}
                rowSpacing={1}
                columnSpacing={{ sm: 1, md: 2 }}>
                <Grid
                  item
                  xs={12}
                  md={6}>
                  <Typography
                    variant='caption'
                    fontSize={17}>
                    Child Name :{" "}
                  </Typography>
                  {child?.firstName + " " + child?.lastName}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}>
                  <Typography
                    variant='caption'
                    fontSize={17}>
                    Date of Birth :{" "}
                  </Typography>
                  {new Date(child?.dob+'Z').toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    timeZone: "America/Chicago",
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}>
                  <Typography
                    variant='caption'
                    fontSize={17}>
                    Desired Start Date :
                    {new Date(child?.joinededDate+'Z').toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      timeZone: "America/Chicago",
                    })}
                  </Typography>
                </Grid>
              </Grid>
              {data.children.length > index + 1 ? <Divider /> : ""}
            </React.Fragment>
          );
        })}
      </Box>
    );
  else <Box>no data</Box>;
};

const ParentInfoModal = (props) => {
  const parent = useSelector(getSelectedParent);

  const actions = (
    <React.Fragment>
      <Button
        size='small'
        color='error'
        variant='outlined'
        onClick={() => props.closeHandle()}>
        Close
      </Button>
    </React.Fragment>
  );

  return (
    <ModalContent
      header={parent?.fullName}
      content={cardContent(parent)}
      action={actions}
      handleClose={props.modalCloseHandle}
      // isLoading={isLoading}
      // isError={isError}
      // error={error}
      // isOpen={open}
    />
  );
};
ParentInfoModal.propTypes = {
  id: PropTypes.string.isRequired,
  modalCloseHandle: PropTypes.func.isRequired,
};
export default React.forwardRef((props, ref) => (
  <ParentInfoModal
    {...props}
    forwardedRef={ref}
  />
));

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { grey, purple, red } from "@mui/material/colors";

ModalContent.propTypes = {
  content: PropTypes.element,
  header: PropTypes.element,
  title: PropTypes.string,
  action: PropTypes.element,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
};

function ModalContent(props) {
  return (
    <React.Fragment>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          background: purple[200],
          display: "flex",
          justifyContent: "space-between",
        }}>
        {props.header ? props.header : props.title}
        <IconButton
          aria-label='close'
          onClick={props.handleClose}
          sx={{
            color: (theme) => theme.palette.grey[700],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {props.isLoading ? (
        <Box
          sx={{
            height: "10rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CircularProgress color='secondary' />
          <Typography variant='h4'>Loading</Typography>
        </Box>
      ) : props.isError ? (
        <React.Fragment>
          <DialogContent
            sx={{
              height: "10rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "2rem",
            }}>
            <Typography
              pt={2}
              variant='h6'
              color={red[600]}>
              Error!
            </Typography>
            <Typography pl={2}>{props.error}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={props.handleClose}>
              Close
            </Button>
          </DialogActions>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DialogContent sx={{ padding: 0 }}>{props.content}</DialogContent>
          <DialogActions sx={{background:grey[200]}}>{props.action}</DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ModalContent;

export const TourRequestStatusEnum = {
    Pending: 100,
    Responded: 150,
    Scheduled: 200,
    Rescheduled: 250,
    Confirmed: 300,
    ScheduleRequested: 350,
    Visited: 400,
    EnrolmentInitiated: 450,
    Enrolled: 500,
    Canceled: 600,
    Expired: 700
};
import React from "react";
import useTitle from "../../hooks/useTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authService } from "../../services/auth.service";
import { ThemeProvider } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormHelperText,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";

const theme = createTheme();

const ResetPasswordPage = () => {
  useTitle("Reset Password");
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [searchParams] = useSearchParams();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let newPassword = data.get("newPassword");
    let repeatedPassword = data.get("repeatedPassword");
    let email = data.get("email");

    if (!email) {
      setError("Email is required");
    } else if (!newPassword || !repeatedPassword) {
      setError("Password is required");
    } else if (newPassword.length < 8) {
      setError("Password must be at least 8 characters");
    } else if (!/[A-Z]/.test(newPassword)) {
      setError("Password must contain at least one uppercase letter");
    } else if (!/[a-z]/.test(newPassword)) {
      setError("Password must contain at least one lowercase letter");
    } else if (!/\d/.test(newPassword)) {
      setError("Password must contain at least one number");
    } else if (!/[!@#\$%^&*]/.test(newPassword)) {
      setError("Password must contain at least one special character");
    } else if (newPassword !== repeatedPassword) {
      setError("Passwords do not match");
    } else {
      setError(null);
      let data = {
        Email: email,
        NewPassword: newPassword,
        ResetPasswordToken: searchParams.get("token"),
      };
      authService
        .resetPassword(data)
        .then((s) => {
          if (s.isSuccess) {
            navigate("/login");
          } else {
            setError(s.message);
          }
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component='main'>
        <CssBaseline />
        <Box
          sx={{
            maxWidth: "35rem",
            margin: " 6rem auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "50px",
            background: "#e0e0e0",
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
            padding: "5rem 2rem",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <KeyIcon />
          </Avatar>
          <Typography
            component='h1'
            variant='h5'>
            Reset Password
          </Typography>
          <Typography variant='body2'>
            Please enter the email address associated with your account and
            we'll send you a link to reset your password.
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='newPassword'
              label='New Password'
              name='newPassword'
              type='password'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='repeatedPassword'
              label='Confirm New Password'
              name='repeatedPassword'
              type='password'
            />

            <FormHelperText error>{error}</FormHelperText>

            <FormHelperText>
              Password must be at least 8 characters long and contained
              <ul>
                <li>Uppercase letters (A-Z)</li>
                <li>Lowercase letters (a-z)</li>
                <li>Numbers (0-9)</li>
                <li>Special characters (e.g., !, @, #, $, %, etc.)</li>
              </ul>
            </FormHelperText>

            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}>
              Reset Password
            </Button>
            <Button
              onClick={() => navigate("/login")}
              fullWidth
              variant='text'
              sx={{ mt: 3, mb: 2 }}>
              Go Back to Login
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;

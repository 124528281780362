import React from "react";
import "./topBar.scss";
import Avatar from "@mui/material/Avatar";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Fab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUser, logout } from "../../features/auth/AuthSlice";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(firstName, lastName) {
  return {
    sx: {
      bgcolor: lastName ? stringToColor(lastName) : "#74FFD3",
    },
    children: `${firstName ? firstName[0] : ""}${lastName ? lastName[0] : ""}`,
  };
}

const TopBar = () => {
  const dispatch = useDispatch();
  const user = useSelector(getLoggedUser);
  return (
    <div className='top-bar-container'>
      <div className='notification-btn top-bar-btn'>
        <NotificationsRoundedIcon />
      </div>
      <Avatar
        className='profile-icon top-bar-btn'
        {...stringAvatar(user?.firstName, user?.lastName)}
        src=''></Avatar>
      <Fab
        color='secondary'
        aria-label='logout'
        onClick={() => dispatch(logout())}>
        <LogoutRoundedIcon />
      </Fab>
    </div>
  );
};

export default TopBar;

import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalContent from "../../components/Modal/ModalContent";
import { Button } from "@mui/material";
import ParentForm from "./ParentForm";
import { useDispatch } from "react-redux";
import { createParent } from "./ParentsSlice";

const CreateParentModal = (props) => {
  const [children, setChildren] = useState([0]);
  const dispatch = useDispatch();

  const formRef = React.useRef(null);

  const onClickCreateParent = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const handleSubmit = async (d) => {
    let parentData = {
      ...d,
      children: [
        ...d.children
          .filter((c) => c.firstName !== "" && c.lastName !== "")
          .map((c) => ({ ...c, dob: c.dob.toISOString() })),
      ],
    };
    dispatch(createParent(parentData));
  };
  const addChild = () => {
    setChildren((prevChildren) => [
      ...prevChildren,
      prevChildren[prevChildren.length - 1] + 1,
    ]);
  };
  const removeChild = (index) => {
    if (children.length > 1)
      setChildren((prevChildren) => [
        ...prevChildren.filter((item) => item !== index),
      ]);
    else {
      // send a error
    }
  };

  const content = (
    <ParentForm
      onSubmit={handleSubmit}
      formRef={formRef}
      children={children}
      removeChildHandle={removeChild}
    />
  );

  const actions = (
    <React.Fragment>
      <Button
        onClick={addChild}
        variant='contained'>
        Add a Child
      </Button>
      <Button
        onClick={onClickCreateParent}
        variant='contained'
        color='success'>
        Create Parent
      </Button>
    </React.Fragment>
  );

  return (
    <ModalContent
      title={"Add Parent"}
      content={content}
      action={actions}
      handleClose={props.modalCloseHandle}
    />
  );
};

CreateParentModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => (
  <CreateParentModal
    {...props}
    forwardedRef={ref}
  />
));

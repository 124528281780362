import React from "react";
import PropTypes from "prop-types";
import ModalContent from "../../components/Modal/ModalContent";
import { Button } from "@mui/material";
import TemplateForm from "./TemplateForm";
import { useDispatch } from "react-redux";
import { createTemplate } from "./FormTemplateSlice";

const CreateFormTemplateModal = (props) => {
  const dispatch = useDispatch();
  const formRef = React.useRef(null);

  const onClickCreateTemplate = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const handleSubmit = async (templateData) => {
    dispatch(createTemplate(templateData));
  };

  const content = (
    <TemplateForm
      onSubmit={handleSubmit}
      formRef={formRef}
      isEdit={false}
    />
  );

  const actions = (
    <React.Fragment>
      <Button
        onClick={onClickCreateTemplate}
        variant='contained'
        color='success'>
        Create Template
      </Button>
    </React.Fragment>
  );

  return (
    <ModalContent
      title={"Add Template"}
      content={content}
      action={actions}
      handleClose={props.modalCloseHandle}
    />
  );
};

CreateFormTemplateModal.propTypes = {
  modalCloseHandle: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => (
  <CreateFormTemplateModal
    {...props}
    forwardedRef={ref}
  />
));

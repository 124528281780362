import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { createTourRequest } from "../../services/tour-request-management.service";

const TourRequestForm = () => {
  const [children, setChildren] = useState([0]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const formRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const addChild = () => {
    setChildren((prevChildren) => [
      ...prevChildren,
      prevChildren[prevChildren.length - 1] + 1,
    ]);
  };
  const removeChild = (index) => {
    if (children.length > 1)
      setChildren((prevChildren) => [
        ...prevChildren.filter((item) => item !== index),
      ]);
    else {
      // send a error
    }
  };

  const onSubmit = async (d) => {
    let requestData = {
      ...d,
      children: [
        ...d.children
          .filter((c) => c.firstName !== "" && c.lastName !== "")
          .map((c) => ({ ...c, dob: c.dob.toISOString() })),
      ],
    };

    let { isSuccess, result, error } = await createTourRequest(requestData);
    setIsSubmitted(isSuccess);
    setError(error);
  };

  const childFields = (child, index) => {
    const fieldName = `children[${child}]`;
    return (
      <Box key={fieldName}>
        <Divider textAlign='left'>
          <Chip
            label={`Child ${index + 1}`}
            size='small'
          />
        </Divider>
        <Grid
          p={2}
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              {...register(`${fieldName}.firstName`, {
                required: "Child's first name is required.",
              })}
              label={"First Name*"}
              error={errors?.children && !!errors?.children[child]?.firstName}
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.firstName?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              {...register(`${fieldName}.lastName`, {
                required: "Child's last name is required.",
              })}
              label={"Last Name*"}
              error={errors?.children && !!errors?.children[child]?.lastName}
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.lastName?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              InputLabelProps={{ shrink: true }}
              type={"date"}
              inputProps={{
                inputMode: "date",
                // max: new Date().toISOString().split("T")[0],
              }}
              {...register(`${fieldName}.dob`, {
                required: "Child's date of birth is required.",
                valueAsDate: true,
                // max: {
                //   value: new Date(),
                //   message: "Child's date of birth must be before today.",
                // },
              })}
              label={"Date of Birth*"}
              error={errors?.children && !!errors?.children[child]?.dob}
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.dob?.message
                  : ""
              }></TextField>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              InputLabelProps={{ shrink: true }}
              type={"date"}
              inputProps={{
                inputMode: "date",
                min: new Date().toISOString().split("T")[0],
              }}
              {...register(`${fieldName}.desiredStartDate`, {
                required: "Child's desired start date is required.",
                valueAsDate: true,
                min: {
                  value: new Date(),
                  message: "Child's desired start date must be before today.",
                },
              })}
              label={"Desired Start Date*"}
              error={
                errors?.children && !!errors?.children[child]?.desiredStartDate
              }
              helperText={
                errors?.children !== undefined
                  ? errors?.children[child]?.desiredStartDate?.message
                  : ""
              }></TextField>
          </Grid>
        </Grid>
        {children?.length > 1 && (
          <Button
            variant='contained'
            color='error'
            size='small'
            sx={{ ml: 3, mb: 3 }}
            onClick={() => removeChild(child)}>
            Remove
          </Button>
        )}
      </Box>
    );
  };

  if (isSubmitted)
    return (
      <Box
        p={2}
        pt={4}>
        <Typography variant='subtitle1'>Thank you!</Typography>
        <Typography variant='subtitle1'>
          We received your request. We will get back to you soon.
        </Typography>
      </Box>
    );
  else
    return (
      <Box
        component='form'
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}>
        {error !== "" && (
          <>
            <Typography
              variant='body1'
              p={2}
              pb={0}
              color={"#d32f2f"}>
              There was an error while trying to submit your message. Please try
              again.
            </Typography>
            <Typography
              variant='body1'
              p={2}
              pt={0}
              color={"#d32f2f"}>
              reason : {error}
            </Typography>
          </>
        )}

        {children.map((child, index) => childFields(child, index))}

        <Grid
          p={2}
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              {...register(`parentFirstName`, {
                required: "Parent's first name is required.",
              })}
              label={"Parent's First Name*"}
              error={!!errors?.parentFirstName}
              helperText={
                errors?.parentFirstName !== undefined
                  ? errors?.parentFirstName?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              {...register(`parentLastName`, {
                required: "Parent's last name is required.",
              })}
              label={"Parent's Last Name*"}
              error={!!errors?.parentLastName}
              helperText={
                errors?.parentLastName !== undefined
                  ? errors?.parentLastName?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              {...register(`parentEmail`, {
                required: "Parent's email is required.",
              })}
              label={"Parent's E-Mail*"}
              error={!!errors?.parentEmail}
              helperText={
                errors?.parentEmail !== undefined
                  ? errors?.parentEmail?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              size='small'
              fullWidth
              {...register(`parentPhoneNumber`, {
                required: "Parent's phone number is required.",
                maxLength: {
                  value: 17,
                  message:
                    "Phone number must be 10 digits as (xxx) xxx-xxxx without brackets and spaces.",
                },
                // onChange: (e) =>{
                //   e.target.value = autoFormatPhoneNumber(e.target.value);
                // },
              })}
              label={"Parent's Phone Number*"}
              error={!!errors?.parentPhoneNumber}
              helperText={
                errors?.parentPhoneNumber !== undefined
                  ? errors?.parentPhoneNumber?.message
                  : ""
              }></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              select
              id='heardAboutUsMethod'
              fullWidth
              size='small'
              defaultValue={4}
              {...register("heardAboutUsMethod", {
                required: "Method of learn about us is required.",
              })}
              label='How did you hear about us?'
              error={!!errors?.heardAboutUsMethod}
              helperText={
                errors?.heardAboutUsMethod !== undefined
                  ? errors?.heardAboutUsMethod?.message
                  : ""
              }>
              <MenuItem
                size='small'
                value={0}
                key={0}>
                Google{" "}
              </MenuItem>
              <MenuItem
                size='small'
                value={1}
                key={1}>
                Facebook{" "}
              </MenuItem>
              <MenuItem
                size='small'
                value={2}
                key={2}>
                DriveBy{" "}
              </MenuItem>
              <MenuItem
                size='small'
                value={3}
                key={3}>
                ParentReferral{" "}
              </MenuItem>
              <MenuItem
                size='small'
                value={4}
                key={4}>
                Other{" "}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}>
            <TextField
              multiline
              size='small'
              fullWidth
              {...register(`heardAbutUsDescription`)}
              label={
                "If you select other, Please tell us how you heard of Radiant"
              }></TextField>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", gap: 2, padding: 3 }}>
          <Button
            onClick={addChild}
            variant='contained'
            size='small'
            color='info'>
            Add Child
          </Button>
          <Button
            type='submit'
            variant='contained'
            size='small'
            color='success'>
            Submit
          </Button>
        </Box>
      </Box>
    );
};

export default TourRequestForm;

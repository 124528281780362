import axios, { queryRequest } from '../config/API';
import { trackPromise } from 'react-promise-tracker';


export const getAllTemplates = async () => {
    return queryRequest({
        url: `/Templates`,
        method: 'GET'
    });
};
export const getTemplateById = async (id) => {
    return queryRequest({
        url: `/Templates/${id}`,
        method: 'GET'
    });
};

export const createTemplate = async (templateData) => {
    try {
        const result = await trackPromise(axios.post(`/templates`, templateData));
        const data = result.data;
        if (data.isSuccess)
            return data.payload;
        return { result: data.payload, isSuccess: data.isSuccess, error: data.message };
    }
    catch (err) {
        throw (err);
    }
};
export const updateTemplateById = async (templateData) => {
    try {
        const result = await trackPromise(axios.put(`/templates/${templateData.id}`, {
            name: templateData.name,
            type: templateData.type,
            zohoTemplateId: templateData.zohoTemplateId,
            isBulkSendEnable:templateData.isBulkSendEnable
        }));
        const data = result.data;
        if (data.isSuccess) {
            return data.payload;
        }
        return { result: data.payload, isSuccess: data.isSuccess, error: data.message };
    }
    catch (err) {
        throw (err);
    }
};
export const deleteTemplateById = async (id) => {
    try {
        const result = await trackPromise(axios.delete(`/templates/${id}`));
        const data = result.data;
        if (data.isSuccess)
            return data.payload;
        return { result: data.payload, isSuccess: data.isSuccess, error: data.message };


    } catch (err) {
        throw (err);
    }
};
export const sendToSign = async (templateId, data) => {
    try {
        const result = await trackPromise(axios.post(`/templates/${templateId}/send`, data));
        const resultData = result.data;
        if (resultData.isSuccess) {
            if (resultData.payload !== null) {
                window.open(resultData.payload, "_self", 'noreferrer');
            }
            return resultData.payload;
        }
        else
            throw (resultData.message);


    } catch (err) {
        throw (err);
    }
};
export const bulkSend = async (templateId, category) => {
    try {
        const result = await trackPromise(
          axios.post(`/templates/${templateId}/bulksend?category=${category}`)
        );
        const resultData = result.data;
        if (resultData.isSuccess) {
            if (resultData.payload !== null) {
                window.open(resultData.payload, "_self", "noreferrer");
            }
            return resultData.payload;
        }
        else
            throw (resultData.message);


    } catch (err) {
        throw (err);
    }
};



const TemplateService = {
    getAllTemplates,
    getTemplateById,
    updateTemplateById,
    deleteTemplateById,
    createTemplate,
    sendToSign,
    bulkSend
};

export default TemplateService; 
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DocumentService from "../../services/document-management-service";
import { Box, Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import SnoozeIcon from "@mui/icons-material/Snooze";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const DocumentSignRedirectPage = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [status, setStatus] = useState("");
  let pageContent = <div></div>;
  useEffect(() => {
    if (id !== "")
      DocumentService.updateDocumentStatus(id)
        .then((status) => {
          if (status === "inprogress") setStatus(type);
          else setStatus(status);
        })
        .catch((err) => {
          setStatus("error");
        })
        .finally((res) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (status) {
    case "completed":
      pageContent = (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <DoneAllIcon sx={{ fontSize: "5rem", color: "#6894ef" }} />
          <Box>
            <strong>Thank you for signing!</strong>
            <p>
              All parties have completed the document and you can download it
              form the Radiant <i>e</i>-Docs.
            </p>
          </Box>
        </Box>
      );
      break;
    case "signed":
      pageContent = (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <DoneIcon sx={{ fontSize: "5rem", color: "#6894ef" }} />
          <Box>
            <strong>Thank you for signing.</strong>
            <p>
              You can see the progress of the document form the Radiant <i>e</i>
              -Docs.
            </p>
          </Box>
        </Box>
      );
      break;
    case "later":
      pageContent = (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <SnoozeIcon sx={{ fontSize: "5rem", color: "#6894ef" }} />
          <Box>
            <strong>You have not completed filling the document</strong>
            <p>Please complete the document before the due date. Thank you!</p>
          </Box>
        </Box>
      );
      break;
    case "declined":
      pageContent = (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <ThumbDownOffAltIcon sx={{ fontSize: "5rem", color: "#6894ef" }} />
          <Box>
            <strong>You have declined the document.</strong>
            <p>Please inform Radiant manager the reason to decline.</p>
          </Box>
        </Box>
      );
      break;
    case "expired":
      pageContent = (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <AlarmOffIcon sx={{ fontSize: "5rem", color: "#6894ef" }} />
          <Box>
            <strong>Your document has passed the due date.</strong>
            <p>Please contact the Radiant manager to request a new document.</p>
          </Box>
        </Box>
      );
      break;
    case "error":
      pageContent = (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <ErrorOutlineIcon sx={{ fontSize: "5rem", color: "#6894ef" }} />
          <Box>
            <strong>An error occurred while saving your document.</strong>
            <p>Please inform the Radiant manager.</p>
          </Box>
        </Box>
      );
      break;
    default:
    // return (
    //   <Navigate
    //     to='/'
    //     replace
    //     state={{ from: location }}
    //   />
    // );
  }
  return (
    <Box
      sx={{
        color: "#505050",
        pl: "8%",
        margin: "auto",
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        {pageContent}

        <Button
          sx={{ width: "max-content" }}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/documents")}
        >
          Go Back to e-Docs
        </Button>
      </Box>
    </Box>
  );
};

export default DocumentSignRedirectPage;

import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Typography,
  useTheme,
  // useMediaQuery,
} from "@mui/material";
import React from "react";
import DataTable from "../../components/data-table/DataTable";
import { DocumentStatusMap } from "../../enumMaps/DocumentStatus.map";
import DocumentService from "../../services/document-management-service";
import { useSelector } from "react-redux";
import { getAllEmployeeDocuments } from "./documentsSlice";
// import { useTheme } from "@emotion/react";
import { getLoggedUser, getLoggedUserRoles } from "../auth/AuthSlice";
import { DocumentStatusEnum } from "../../enums/DocumentStatus.enum";
import StatusSelector from "./StatusSelector";

const columns = [
  {
    name: "documentName",
    disablePadding: false,
    label: "Document Name",
    align: "left",
  },
  {
    name: "inProgressWith",
    disablePadding: false,
    label: "In Progress With",
    align: "left",
  },
  {
    name: "sentBy",
    disablePadding: false,
    label: "Sent By",
    align: "left",
  },
  {
    name: "createdDate",
    disablePadding: false,
    label: "Sent Date",
    align: "left",
    isDate: true,
  },
  {
    name: "dueDate",
    disablePadding: false,
    label: "Due Date",
    align: "left",
    isDate: true,
  },
  {
    name: "status",
    disablePadding: false,
    label: "Status",
    align: "left",
  },
  {
    name: "action",
    disablePadding: false,
    label: "Action",
    align: "right",
  },
];

const EmployeesDocuments = (param) => {
  const currentUserRoles = useSelector(getLoggedUserRoles);
  const currentUser = useSelector(getLoggedUser);
  const documents = useSelector(getAllEmployeeDocuments);

  const [selectedStatus, setSelectedStatus] = React.useState([
    DocumentStatusEnum.Pending,
    DocumentStatusEnum.Completed,
  ]);
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme?.breakpoints?.down("md"));

  const downLoad = (zohoId) => {};
  const viewMore = (id) => {};
  const sendReminder = (zohoId) => {};

  const actionBtn = (id, zohoId, zohoRequestId, status, signers,DocumentSaveStatus) => {
    let inprogressWith = signers?.find(
      (s) => s.status !== 0 && s.status !== DocumentStatusEnum.Completed
    );
    // zohoRequestId = inprogressWith?.actionId | zohoRequestId;
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        {/* <Button
          variant='text'
          id={"delete" + id}
          onClick={() => viewMore(id)}>
          View More
        </Button> */}
        {status === DocumentStatusEnum.Pending &&
          inprogressWith?.userEmail === currentUser.email && (
            <Button
              variant="text"
              id={"sign" + id}
              onClick={() => DocumentService.getSigningLink(id)}
            >
              Sign
            </Button>
          )}
        {status === DocumentStatusEnum.Completed && (
          <Button
            variant="text"
            id={"view" + id}
            onClick={() =>
              DocumentService.getSigningLink(id, zohoId, zohoRequestId)
            }
          >
            View
          </Button>
        )}
         {(status === DocumentStatusEnum.Completed &&
          DocumentSaveStatus &&
          Object.values(DocumentSaveStatus).some((status) => !status)&&
          currentUserRoles.includes("SuperAdmin")
        ) && (
            <Button
              variant="text"
              id={"view" + id}
              onClick={() => DocumentService.updateDocumentStatus(id)}
            >
              Save
            </Button>
          )}
        {/* {status === 300 && (
          <Button
            variant='text'
            id={"delete" + id}
            onClick={() => downLoad(zohoId)}>
            Download
          </Button>
        )} */}
        {/* {status === 100 && (
          <Button
            variant='text'
            id={"delete" + id}
            onClick={() => sendReminder(zohoId)}>
            Send Reminder
          </Button>
        )} */}
      </ButtonGroup>
    );
  };

  const statusChip = (status) => {
    return (
      <Chip
        // size='small'
        label={DocumentStatusMap.get(status).label}
        color={DocumentStatusMap.get(status).color}
        variant={DocumentStatusMap.get(status).variant}
      />
    );
  };

  const inProgressWith = (signers) => {
    let inProgressWith = signers.find(
      (s) => s.status !== 0 && s.status !== DocumentStatusEnum.Completed
    );
    return (
      !!inProgressWith && (
        <Box>
          <Typography variant="body1">{inProgressWith?.userName}</Typography>
          <Typography variant="caption">
            ({inProgressWith?.userEmail})
          </Typography>
        </Box>
      )
    );
  };

  const createRowData = (data) => {
    if (Array.isArray(data)) {
      if (param?.status === 0)
        return data.map((r) => {
          let status = r.status;
          if (!!r.dueDate && r.status !== DocumentStatusEnum.Completed)
            status =
              Date.now() < Date.parse(r.dueDate)
                ? r.status
                : DocumentStatusEnum.Expired;
          return {
            id: r.id,
            documentName: r.name,
            inProgressWith: inProgressWith(r.signers),

            createdDate: new Date(r.createdDate+'Z').toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "America/Chicago",
            }),
            dueDate: !!r.dueDate
              ? new Date(r.dueDate+'Z').toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  timeZone: "America/Chicago",
                })
              : "-",
            completedDated: new Date(r.completedDated+'Z').toLocaleDateString(
              "en-US",{
                timeZone: "America/Chicago",
              }
            ),
            sentBy: r.sentBy.firstName,
            status: statusChip(status),
            action: actionBtn(
              r.id,
              r.zohoRequestId,
              r.signers[0].actionId,
              r.status,
              r.signers,
              r.metaData?.documentSaveStatus
            ),
          };
        });
      else
        return data
          .filter(
            (r) => {
              if (param.status === DocumentStatusEnum.Pending) {
                if (
                  r.status === DocumentStatusEnum.Pending &&
                  Date.now() <= Date.parse(r.dueDate)
                )
                  return r;
                else return null;
              } else if (
                (param.status === DocumentStatusEnum.Expired &&
                  Date.now() > Date.parse(r.dueDate) &&
                  r.status !== DocumentStatusEnum.Completed) ||
                r.status === DocumentStatusEnum.Expired
              )
                return r;
              else if (r.status === param.status) return r;
              else return null;
            }
            // r.status === param.status ||
            // (param.status === 700 &&
            //   Date.now() > Date.parse(r.dueDate) &&
            //   r.status !== 300)
          )
          .map((r) => {
            let status = r.status;
            if (!!r.dueDate && r.status !== 300)
              status = Date.now() < Date.parse(r.dueDate+'Z') ? r.status : 700;
            return {
              id: r.id,
              documentName: r.name,
              inProgressWith: inProgressWith(r.signers),

              createdDate: new Date(r.createdDate+'Z').toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                timeZone: "America/Chicago",
              }),
              dueDate: !!r.dueDate
                ? new Date(r.dueDate+'Z').toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    timeZone: "America/Chicago",
                  })
                : "-",
              completedDated: new Date(r.completedDated+'Z').toLocaleDateString(
                "en-US",{
                  timeZone: "America/Chicago",
                }
              ),
              sentBy: r.sentBy.firstName,
              status: statusChip(status),
              action: actionBtn(
                r.id,
                r.zohoRequestId,
                r.signers[0].actionId,
                r.status,
                r.signers,
                r.metaData?.documentSaveStatus
              ),
            };
          });
    } else return [];
  };

  const onSelectStatus = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStatus(typeof value === "string" ? value.split(",") : value);
  };

  const filters = (
    <Box>
      <StatusSelector 
      selectedStatus={selectedStatus}
      onSelectStatus={onSelectStatus}
      theme={theme}
      />      
    </Box>
  );

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <DataTable
          isDense={false}
          rows={createRowData(documents.filter((r) => selectedStatus.includes(r.status)))}
          columns={columns}
          sortBy={"createdDate"}
          order={"desc"}
          filters={filters}
        />
      </Box>
    </React.Fragment>
  );
};

export default EmployeesDocuments;

import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "./countCard.scss";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const CountCards = ({ count, title, color, type }) => {
  const navigate = useNavigate();
  const onclickCard = (type, status) => {
    navigate("/" + type + "?status=" + status);
  };

  const CardButton = styled(Button)({
    minHeight: '6rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: 'black',
  
    padding: '5px 10px',
    justifyContent: 'flex-start',
    borderRadius: '5px',
    width: '150px',
    border: 0,
    // boxShadow: '3px 2px 24px 1px #0000002e',
  });

  return (
    <CardButton
    className="count-card"
      onClick={() => onclickCard(type, title)}
      variant='outlined'
      sx={{
        background: `linear-gradient(345deg, ${color+'40'}, ${color+'50'})`,
        // border: "1px sold",
        // borderColor: color,
        // "&:hover": {
        //   borderColor: color,
        //   background: color + "A0",
        // },
      }}
      >
      <Typography
      className="title"
        variant='h6'
        component='h1'>
        {title}
      </Typography>
      <Typography
        className='count'
        variant='h2'
        component='h2'
        sx={{ fontSize: "1.7rem" }}>
        {count}
      </Typography>
    </CardButton>
  );
};

export default CountCards;

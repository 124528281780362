import React, { useEffect, useState } from "react";
import DataTable from "../../components/data-table/DataTable";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CreateFormTemplateModal from "./CreateFormTemplateModal";
import { Add } from "@mui/icons-material";
import { TemplateTypeMap } from "../../enumMaps/templateType.map";
import EditFormTemplateModal from "./EditFormTemplateModal";
import useTitle from "../../hooks/useTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedTemplate,
  closeModal,
  deleteTemplateById,
  fetchAllTemplates,
  getAllTemplates,
  getTemplateModalStatus,
  openModal,
  selectTemplate,
  sendToSign,
} from "./FormTemplateSlice";
import SendToSignModal from "./SendToSignModal";
import { getLoggedUser, getLoggedUserRoles } from "../auth/AuthSlice";
import { TemplateTypeEnum } from "../../enums/TemplateType.enum";
import { RecipientCategoryEnum } from "../../enums/RecipientCategory.enum";

const FormTemplatesManagement = () => {
  useTitle("Form Management");
  const dispatch = useDispatch();

  const [modalType, setModalType] = useState("none");
  const [selectedTab, setSelectedTab] = useState("All");

  useEffect(() => {
    dispatch(fetchAllTemplates());
  }, [dispatch]);

  const templates = useSelector(getAllTemplates);
  let isModalOpen = useSelector(getTemplateModalStatus);
  let currentUserRoles = useSelector(getLoggedUserRoles);
  let currentUser = useSelector(getLoggedUser);

  let columns = [
    {
      name: "formName",
      disablePadding: false,
      label: "Form Name",
      align: "left",
    },
    {
      name: "type",
      disablePadding: false,
      label: "Type",
      align: "left",
    },
    // {
    //   name: "createdBy",
    //   disablePadding: false,
    //   label: "Created By",
    //   align: "left",
    // },
    {
      name: "recipients",
      disablePadding: false,
      label: "Recipients",
      align: "left",
    },
    {
      name: "zohoId",
      disablePadding: false,
      label: "Zoho Id",
      align: "left",
    },
    {
      name: "action",
      disablePadding: false,
      label: "Action",
      align: "left",
    },
  ];

  if (currentUserRoles.includes("SuperAdmin")) {
    columns = [
      {
        name: "formName",
        disablePadding: false,
        label: "Form Name",
        align: "left",
      },
      {
        name: "type",
        disablePadding: false,
        label: "Type",
        align: "left",
      },
      // {
      //   name: "createdBy",
      //   disablePadding: false,
      //   label: "Created By",
      //   align: "left",
      // },
      {
        name: "recipients",
        disablePadding: false,
        label: "Recipients",
        align: "left",
      },
      {
        name: "zohoId",
        disablePadding: false,
        label: "Zoho Id",
        align: "left",
      },
      {
        name: "action",
        disablePadding: false,
        label: "Action",
        align: "left",
      },
    ];
  } else if (currentUserRoles.includes("Admin")) {
    columns = [
      {
        name: "formName",
        disablePadding: false,
        label: "Form Name",
        align: "left",
      },
      {
        name: "type",
        disablePadding: false,
        label: "Type",
        align: "left",
      },
      {
        name: "recipients",
        disablePadding: false,
        label: "Recipients",
        align: "left",
      },
      {
        name: "action",
        disablePadding: false,
        label: "Action",
        align: "left",
      },
    ];
  } else {
    columns = [
      {
        name: "formName",
        disablePadding: false,
        label: "Form Name",
        align: "left",
      },
      {
        name: "recipients",
        disablePadding: false,
        label: "Recipients",
        align: "left",
      },
      {
        name: "action",
        disablePadding: false,
        label: "Action",
        align: "left",
      },
    ];
  }

  const onClickTemplateCreate = () => {
    dispatch(openModal());
    setModalType("create");
  };

  const onClickTemplateEdit = (data) => {
    dispatch(openModal());
    dispatch(selectTemplate({ id: data.id }));
    setModalType("edit");
  };

  const onClickSend = (data) => {
    dispatch(openModal());
    dispatch(selectTemplate({ id: data.id }));
    setModalType("send");
  };

  const onclickInitiate = (template) => {
    // TODO: Check there is any initiated forms for this template
    let recipients = template.recipients.map((r, i) => {
      if (i === 0 && r.email === "") {
        return {
          ...r,
          email: currentUser.email,
          name: currentUser.firstName + " " + currentUser.lastName,
        };
      } else return r;
    });
    dispatch(
      sendToSign({
        templateId: template.id,
        requestData: {
          childId: null,
          employeeId: null,
          recipients: recipients,
        },
      })
    );
  };

  // TODO: View
  // const onClickTemplateView = (data) => {
  //   dispatch(openModal());
  //   dispatch(selectTemplate({ id: data.id }));
  //   setModalType("edit");
  // };
  // TODO: Delete
  const onClickTemplateDelete = (data) => {
    // dispatch(openModal());
    // dispatch(selectTemplate({ id: data.id }));
    // setModalType("delete");
    dispatch(deleteTemplateById(data.id));
  };

  const handleModalClose = () => {
    dispatch(clearSelectedTemplate());
    dispatch(closeModal());
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const actionButtons = (templateData) => {
    let isInitiate =
      currentUserRoles.includes("Employee") &&
      templateData?.recipients[0]?.category ===
        RecipientCategoryEnum.EMPLOYEE &&
      templateData?.type === TemplateTypeEnum.Employee_Request_Forms;

    let isAdminCanSend =
      (currentUserRoles.includes("SuperAdmin") ||
        currentUserRoles.includes("Admin")) &&
      templateData?.type === TemplateTypeEnum.Employee_Request_Forms;

    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        {currentUserRoles.includes("SuperAdmin") && (
          <Button
            variant="text"
            id={templateData.id + "-edit-btn"}
            onClick={(id) => onClickTemplateEdit(templateData)}
          >
            Edit {/* <EditIcon /> */}
          </Button>
        )}

        {currentUserRoles.includes("SuperAdmin") && (
          <Button
            color="error"
            variant="text"
            id={templateData.id + "-delete-btn"}
            onClick={(id) => onClickTemplateDelete(templateData)}
          >
            Delete{/* <DeleteIcon /> */}
          </Button>
        )}

        {(isInitiate && (
          <Button
            color="success"
            variant="text"
            id={templateData.id + "-initiate-btn"}
            onClick={(id) => onclickInitiate(templateData)}
          >
            Initiate
          </Button>
        )) ||
          (!isAdminCanSend && (
            <Button
              color="success"
              variant="text"
              id={templateData.id + "-send-btn"}
              onClick={(id) => onClickSend(templateData)}
            >
              Send
            </Button>
          ))}
      </ButtonGroup>
    );
  };

  const recipients = (recipients, formType) => {
    let placeHolder = formType < 2000 ? "Parent" : "Employee";
    let signers_str = "";
    recipients.forEach((rc, idx, array) => {
      signers_str += rc.name === "" ? placeHolder : rc.name;
      signers_str += idx < array.length - 1 ? " → " : "";
    });
    return signers_str;
  };

  const templateTypeChip = (type) => {
    return (
      <Chip label={type.label} variant={type.variant} color={type.color} />
    );
  };

  const createRowData = (data) => {
    if (Array.isArray(data))
      switch (selectedTab) {
        case "Student":
          return data
            .filter(
              (t) =>
                t.type === TemplateTypeEnum.General_Student ||
                t.type === TemplateTypeEnum.Initial_Enrolment
            )
            .map((r) => ({
              id: r.id,
              formName: r.name,
              type: templateTypeChip(TemplateTypeMap.get(r.type)),
              createdBy: r.createdBy?.firstName,
              recipients: recipients(r.recipients, r.type),
              zohoId: r.zohoTemplateId,
              action: actionButtons(r),
            }));
        case "Employee":
          return data
            .filter(
              (t) =>
                t.type === TemplateTypeEnum.General_Employee ||
                t.type === TemplateTypeEnum.Initial_Hiring ||
                t.type === TemplateTypeEnum.Employee_Request_Forms
            )
            .map((r) => ({
              id: r.id,
              formName: r.name,
              type: templateTypeChip(TemplateTypeMap.get(r.type)),
              createdBy: r.createdBy?.firstName,
              recipients: recipients(r.recipients, r.type),
              zohoId: r.zohoTemplateId,
              action: actionButtons(r),
            }));
        case "All":
        default:
          return data.map((r) => ({
            id: r.id,
            formName: r.name,
            type: templateTypeChip(TemplateTypeMap.get(r.type)),
            createdBy: r.createdBy?.firstName,
            recipients: recipients(r.recipients, r.type),
            zohoId: r.zohoTemplateId,
            action: actionButtons(r),
          }));
      }
    else return [];
  };

  const onchangeType = (e, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "column",
          // gap: 1,
        }}
      >
        {(currentUserRoles.includes("SuperAdmin") ||
          currentUserRoles.includes("Admin")) && (
          <div>
            {currentUserRoles.includes("SuperAdmin") && (
              <Button
                variant="outlined"
                onClick={onClickTemplateCreate}
                startIcon={<Add />}
              >
                Add Form Template
              </Button>
            )}
            <Tabs value={selectedTab} onChange={onchangeType} centered>
              <Tab label="All" value={"All"} />
              <Tab label="Student Forms" value={"Student"} />
              <Tab label="Employee Forms" value={"Employee"} />
            </Tabs>
          </div>
        )}

        <Box className="info-section">
          <DataTable
            isDense={true}
            rows={createRowData(templates)}
            columns={columns}
            sortBy={"formName"}
          />
        </Box>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        sx={{ minHeight: "50vh" }}
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="template-info"
      >
        {modalType === "create" && (
          <CreateFormTemplateModal modalCloseHandle={handleModalClose} />
        )}
        {modalType === "edit" && (
          <EditFormTemplateModal modalCloseHandle={handleModalClose} />
        )}
        {modalType === "send" && (
          <SendToSignModal modalCloseHandle={handleModalClose} />
        )}
        {modalType === "delete" && (
          <SendToSignModal modalCloseHandle={handleModalClose} />
        )}
        {/* {modalType==='view' && (
          <EmployeeInfoModal
            modalCloseHandle={handleModalClose}
            id={selectedEmployeeId}
          />
        )} */}
      </Dialog>
    </React.Fragment>
  );
};

export default FormTemplatesManagement;

import React from "react";
import { Link } from "react-router-dom";
import "./sidebar.scss";
import { useDispatch } from "react-redux";
import { closeSideBar } from "../layoutSlice";

const SidebarItem = (props) => {
  const dispatch = useDispatch();
  return (
    <Link
      to={props.route}
      className={`sidebar-item ${props.isSelected ? "selected" : ""}`}
      onClick={() => {
        if (props.isFullScreen) dispatch(closeSideBar());
      }}
    >
      {props.icon}
      <span>{props.title}</span>
    </Link>
  );
};

export default SidebarItem;

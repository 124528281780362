import React from "react";
import PropTypes from "prop-types";

const NotFoundPage = (props) => {
  return <div>NOT FOUND</div>;
};

NotFoundPage.propTypes = {};

export default NotFoundPage;
